import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "running-scripts"
    }}>{`Running Scripts`}</h3>
    <p>{`As introduced in `}<a parentName="p" {...{
        "href": "/operating/cli/"
      }}>{`CLI`}</a>{` document, Pipy can load scripts from local static files or via an HTTP interface. You can also load scripts by clicking on the "run button" located in the uppoer right corner of the `}<a parentName="p" {...{
        "href": "/operating/admin-gui/"
      }}>{`Admin Console GUI`}</a>{`.`}</p>
    <p>{`After creating a `}<em parentName="p">{`codebase`}</em>{` in Pipy Repo, we will use an HTTP interface to start our scripts, assuming we have created a `}<inlineCode parentName="p">{`hello`}</inlineCode>{` `}<em parentName="p">{`codebase`}</em>{` in Pipy Repo. We can invoke Pipy as below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ pipy http://localhost:6060/repo/hello/
2021-10-09 12:28:52 [info] [codebase] GET http://localhost:6060/repo/hello/ -> 8 bytes
2021-10-09 12:28:52 [info] [codebase] GET /repo/hello/main.js -> 7 bytes
2021-10-09 12:28:52 [info] [config]
2021-10-09 12:28:52 [info] [config] Module /main.js
2021-10-09 12:28:52 [info] [config] ===============
2021-10-09 12:28:52 [info] [config]
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` script does not listen on any ports and changes won't take effect until they are committed. After you click the submit button, you'll see that Pipy reloads the script and listens on port `}<inlineCode parentName="p">{`8080`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`pipy http://localhost:6060/repo/hello/
2021-10-09 12:28:52 [info] [codebase] GET http://localhost:6060/repo/hello/ -> 8 bytes
2021-10-09 12:28:52 [info] [codebase] GET /repo/hello/main.js -> 7 bytes
2021-10-09 12:28:52 [info] [config]
2021-10-09 12:28:52 [info] [config] Module /main.js
2021-10-09 12:28:52 [info] [config] ===============
2021-10-09 12:28:52 [info] [config]
2021-10-09 12:29:37 [info] [codebase] GET http://localhost:6060/repo/hello/ -> 8 bytes
2021-10-09 12:29:37 [info] [codebase] GET /repo/hello/main.js -> 65 bytes
2021-10-09 12:29:37 [info] [config]
2021-10-09 12:29:37 [info] [config] Module /main.js
2021-10-09 12:29:37 [info] [config] ===============
2021-10-09 12:29:37 [info] [config]
2021-10-09 12:29:37 [info] [config]  [Listen on :::8080]
2021-10-09 12:29:37 [info] [config]  ----->|
2021-10-09 12:29:37 [info] [config]        |
2021-10-09 12:29:37 [info] [config]       serveHTTP
2021-10-09 12:29:37 [info] [config]        |
2021-10-09 12:29:37 [info] [config]  <-----|
2021-10-09 12:29:37 [info] [config]
2021-10-09 12:29:37 [info] [listener] Listening on port 8080 at ::
2021-10-09 12:29:37 [info] Script reloaded
`}</code></pre>
    <p>{`Let's do a test:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl http://localhost:8080
Hi!
`}</code></pre>
    <p>{`Make changes to script, save and submit and see changes taking effect `}<strong parentName="p">{`without restarting pipy instance`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`pipy()
  .listen(8080)
  .serveHTTP(
    new Message('Hello world!')
  )
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl http://localhost:8080
Hello world!
`}</code></pre>
    <h3 {...{
      "id": "derived-codebase"
    }}>{`Derived Codebase`}</h3>
    <h4 {...{
      "id": "1-make-above-script-a-little-bit-more-complicated-and-implement-a-simple-logic-that-separate-code-from-the-configuration"
    }}>{`1. Make above script a little bit more complicated and implement a simple logic that separate code from the configuration`}</h4>
    <p><strong parentName="p">{`main.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`(config =>
  pipy()
  .listen(config.port)
  .serveHTTP(
    () => new Message(config.greating)
  )
)(JSON.decode(pipy.load('config.json')))
`}</code></pre>
    <p>{`Add new file: `}<strong parentName="p">{`config.json`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "port": 8080,
  "greating": "Hello world!"
}
`}</code></pre>
    <h4 {...{
      "id": "2-derivative"
    }}>{`2. Derivative`}</h4>
    <p>{`Click "Derivates" on the toolbar to select "Derive new codebase" and add the name of the derived codebase, such as `}<inlineCode parentName="p">{`hi`}</inlineCode>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/derive-codebase.png",
        "alt": null
      }}></img></p>
    <h4 {...{
      "id": "3-new-codebase"
    }}>{`3. New Codebase`}</h4>
    <p>{`You can see from the derived codebase interface:`}</p>
    <ul>
      <li parentName="ul">{`The file name in the file list becomes gray, indicating that the file contents are same with content in the base library. Otherwise, the file contents are different from that of the base library.`}</li>
      <li parentName="ul">{`The "Base" button on the toolbar is activated to return to the Base library interface.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/derivative-codebase.png",
        "alt": null
      }}></img></p>
    <h4 {...{
      "id": "4-changes"
    }}>{`4. Changes`}</h4>
    <p>{`Lets Change `}<inlineCode parentName="p">{`greeting`}</inlineCode>{` contents  of derivate file `}<inlineCode parentName="p">{`config.json`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "port": "8081",
  "greeting": "Hi there!"
}
`}</code></pre>
    <p>{`After save and submi, we can see that `}<inlineCode parentName="p">{`config.json`}</inlineCode>{` color has returned to normal color.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/update-config.png",
        "alt": null
      }}></img></p>
    <h4 {...{
      "id": "5-test"
    }}>{`5. Test`}</h4>
    <p>{`Let's launch another Pipy instance using the derived library and test it out:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ pipy http://localhost:6060/repo/hi/
$ curl http://localhost:8080
Hello world!
$ curl http://localhost:8081
Hi there!
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      